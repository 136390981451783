import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';
import Player from '@vimeo/player';

let carousels = [];
let carouselLoaded = false
let carouselUnderWidth = false

function initVideo(el, carouselObject) {
  const video = new Player(el, {
    url: el.href,
    controls: true
  });

  carouselObject.videos.push(video);
}

function handleImageLoading(parent, carousel) {
  const imgs = parent.querySelectorAll('img')
  for(const img of imgs) {
    if(img.complete) carousel.reposition()
    else img.onload = () => {
      carousel.reposition()
      disableWrapAround(parent, carousel)
    }
  }
  disableWrapAround(parent, carousel)
}
function disableWrapAround(parent, carousel) {
  const imgs = parent.querySelectorAll('img')
  const carouselDotContainer = parent.querySelector('.flickity-page-dots')
  if(carouselDotContainer) carouselDotContainer.style.pointerEvents = 'none'
  const carouselDots = parent.querySelectorAll('.flickity-page-dot')
  for(const dot of carouselDots) {
    dot.style.pointerEvents = 'auto'
  }
  let loaded = 0
  let imgConcatWidth = 0
  for(const img of imgs) {
    if(img.complete) {
      loaded += 1
      imgConcatWidth += img.clientWidth
    }
  }
  carouselLoaded = loaded === imgs.length  
  carouselUnderWidth = parent.clientWidth > imgConcatWidth
  if(carouselLoaded) {
    if(carouselUnderWidth) {
      carousel.options.draggable = false
      carousel.options.wrapAround = false
      carousel.options.pageDots = false
      carousel.select(0)
      for(const dot of carouselDots) {
        dot.setAttribute('disabled', true)
      }
    } else {
      carousel.options.draggable = true
      carousel.options.wrapAround = true
      carousel.options.pageDots = true
      carousel.select(0)
      for(const dot of carouselDots) {
        dot.removeAttribute('disabled')
      }
    }
  }
}

function initCarousel(el) {
  // Wrap it in an object so we scope videos to their respective carousel parents.
  let carouselObject = {
    carousel: {},
    videos: []
  };

  const parent = el.parentElement;
  const prev = parent.querySelector('.js-carousel-prev');
  const next = parent.querySelector('.js-carousel-next');
  const numberEl = parent.querySelector('.js-carousel-index');

  carouselObject.carousel = new Flickity(el, {
    freeScroll: false,
    contain: false,
    cellAlign: 'left',
    pageDots: false,
    prevNextButtons: false,
    setGallerySize: false,
    wrapAround: true,
    imagesLoaded: true,
    lazyLoad: true,
    on: {
      ready: function () {
        const videoEls = document.querySelectorAll('.js-slider-video');
        videoEls.forEach((el) => {
          initVideo(el, carouselObject);
        });
        carousels.push(carouselObject);
        numberEl.innerHTML = 1
        setTimeout(() => {
          handleImageLoading(parent, carouselObject.carousel)
        }, 1)
      },
      change: function () {
        // carouselObject.videos.forEach((video) => video.pause());
      },
      scroll: function () {
        numberEl.innerHTML = carouselObject.carousel.selectedIndex + 1
      },
      resize: function () {
        disableWrapAround(parent, carouselObject.carousel)
      }
    }
  });

  const { carousel } = carouselObject;

  prev.addEventListener('click', function () {
    if(carouselLoaded && !carouselUnderWidth) carousel.previous();
    numberEl.innerHTML = carousel.selectedIndex + 1
  });

  next.addEventListener('click', function () {
    if(carouselLoaded && !carouselUnderWidth) carousel.next();
    numberEl.innerHTML = carousel.selectedIndex + 1
  });
}

export const carousel = () => {
  const els = document.querySelectorAll('.js-carousel');
  els.forEach((el) => initCarousel(el));
};
