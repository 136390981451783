const shuffle = (container) => {
  for (var i = container.children.length; i >= 0; i--) {
    container.appendChild(container.children[(Math.random() * i) | 0]);
  }
};


const limitShuffle = (container, amount) => {
  shuffle(container)

  if(amount <= 0) amount = 3

  let children = container.children
  let index = children.length - 1
  console.log(children.length)
  while(children.length > amount) {
    children[index].parentNode.removeChild(children[index])
    index -= 1
  }
}



export const initShuffle = () => {
  const containerToShuffle = document.querySelectorAll('.js-shuffle');

  if (containerToShuffle.length) {
    containerToShuffle.forEach((container) => {
      shuffle(container);
    });
  }

  const recommendedContainer = document.querySelectorAll('.js-related-shuffle');
  if (recommendedContainer.length) {
    recommendedContainer.forEach((container) => {
      limitShuffle(container, 3);
    });
  }
};


