import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const setupReveal = (container) => {
  // Set initial styles
  const elemsToReveal = container.querySelectorAll('[data-reveal]');
  const arrowTop = container.querySelector('.reveal__arrow--top');
  const arrowBottom = container.querySelector('.reveal__arrow--bottom');
  const containerHeight = container.getBoundingClientRect().height;

  // Set initial state
  gsap.set(container, { height: () => containerHeight / 2 });
  elemsToReveal.forEach((elem) => elem.classList.add('hidden'));

  // Setup scroll animation
  const timeline = gsap
    .timeline()
    .addLabel('start', 0)
    .to(
      container,
      {
        height: containerHeight,
        duration: 1,
        ease: 'power1.out',
        onComplete: () => (container.style.height = 'auto')
      },
      'start'
    )
    .fromTo(
      elemsToReveal,
      {
        opacity: 0,
        y: '-101%',
        scale: 0.5
      },
      {
        y: 0,
        opacity: 1,
        scale: 1,
        stagger: 0.25,
        ease: 'power1.out'
      },
      'start+=0.25'
    )
    .to(
      arrowTop,
      {
        x: '100%',
        y: '-100%',
        opacity: 0,
        duration: 0.25
      },
      'start+=0.5'
    )
    .to(
      arrowBottom,
      {
        x: '-100%',
        y: '100%',
        opacity: 0,
        duration: 0.25
      },
      'start+=0.5'
    );

  // Create scroll trigger
  ScrollTrigger.create({
    trigger: container,
    animation: timeline,
    start: 'top center'
  });
};

export const initReveal = () => {
  const targets = document.querySelectorAll('.reveal');

  if (targets.length > 0) {
    targets.forEach((target) => setupReveal(target));
  }
};
