import gsap from '../../gsap/dist/gsap.js';
const activeClass = 'submenu-is-active';

function show(menu, button) {
  button.classList.add(activeClass);
  menu.classList.add(activeClass);
  const tl = gsap.timeline();
  tl.fromTo(menu, {
    height: 0
  }, {
    height: 'auto',
    duration: 0.2
  });
  return tl;
}

function hide(menu, button) {
  button.classList.remove(activeClass);
  const tl = gsap.timeline();
  tl.to(menu, {
    height: 0,
    duration: 0.2,
    onComplete: () => {
      menu.classList.remove(activeClass);
    }
  });
  return tl;
}

function toggle(button) {
  const selector = button.dataset.submenu;
  const menu = document.getElementById(selector);
  const activeMenu = document.querySelector(`.js-subnav-menu.${activeClass}`);
  const activeButton = document.querySelector(`.js-subnav-button.${activeClass}`);

  if (activeMenu) {
    if (activeMenu === menu) {
      hide(activeMenu, activeButton);
    } else {
      const tl = gsap.timeline();
      tl.add(hide(activeMenu, activeButton)).add(show(menu, button));
    }
  } else {
    show(menu, button);
  }
}

export const subnav = () => {
  document.addEventListener('click', function (e) {
    const button = e.target.closest('.js-subnav-button');
    const resetButton = e.target.closest('.js-subnav-reset');

    if (button) {
      toggle(button);
    }
  });
}