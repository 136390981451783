import Player from '@vimeo/player';

function initVideo(el) {
  let quality = el.classList.contains('hero__media') ? 720 : 540
  let auto = el.classList.contains('autop') ? true : false
  const options = {
    url: el.href || el.dataset.vimeoUrl,
    quality,
    controls: true,
    autoplay: auto
  }

  new Player(el, options);
}

export const initVideos = () => {
  const els = document.querySelectorAll('.js-video');
  els.forEach((el) => initVideo(el));
};
