const init = (elements, enable, disable) => {
  for (const element of elements) {
    addOffscreenEventListener(element, enable, disable)
  }
}

const OffscreenHandler = (element, enable, disable) => {
  const bodyRect = document.body.getBoundingClientRect(),
    elementRect = element.getBoundingClientRect(),
    elementY = elementRect.top - bodyRect.top,
    elementHeight = element.clientHeight,
    scrollY = window.scrollY
  const isOffscreen = scrollY + window.innerHeight < elementY || scrollY > elementY + elementHeight
  if (isOffscreen) disable(element)
  else enable(element)
}

const addOffscreenEventListener = (element, enable, disable) => {
  if (!element) return
  window.addEventListener('scroll', () => OffscreenHandler(element, enable, disable))
  window.addEventListener('onfocus', () => OffscreenHandler(element, enable, disable))
  OffscreenHandler(element, enable, disable)
}

export default init