import { initNavigation } from './modules/navigation';
import { scrollListener } from './modules/scroll';
import { mountHeroSlider } from './modules/slider';
import { initReveal } from './modules/reveal';
import { initPractice } from './modules/practice';
import { sideNav } from './modules/side-nav';
import { initFilterBack } from './modules/filter-back';
import { initVideos } from './modules/video';
import { subnav } from './modules/subnav';
import { search } from './modules/search';
import { initShuffle } from './modules/shuffle';
import { carousel } from './modules/carousel';
import { setupLazyImages } from './modules/lazy-image';

import { initObjectPositioning } from './modules/object-positioning'
import initOffscreenVideo from './modules/init-offscreen-video';
import { initNavLinkHover } from './modules/nav-link'

mountHeroSlider();
initNavigation();
initPractice();
scrollListener();
sideNav();
initFilterBack();
initVideos();
subnav();
search();
initShuffle();
carousel();
setupLazyImages();

const mounted = () => {
  initReveal();
  initOffscreenVideo();

  initObjectPositioning();
  initNavLinkHover();
}

// We have to wait for the page to load for reveal animations
// to be setup so height is calculated correctly
window.addEventListener('load', function () {

  let oldHref = document.location.href;
  var observer = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
        if (oldHref != document.location.href) {
            oldHref = document.location.href;
            mounted()
        }
    });
  });
  observer.observe(document, { childList: true, subtree: true });
  mounted()
});

