

const objectPositioningHandler = () => {
  const images = document.querySelectorAll('img[data-op]')
  const windowWidth = window.innerWidth
  images.forEach(image => {
    const opset = image.getAttribute('data-op')
    let array = opset.split(',')
    array = array.map(data => data.split('-'))
    // This should create an array of an array of three values each so:
    /**
     * [
     *  [width, x, y],
     *  [width, x, y]
     * ]
     *  */
    let found = false
    for(let i = 0; i < array.length; i++) {
      let opdata = array[i]
      let width = opdata[0]
      if(width >= windowWidth) {
        found = opdata
        break
      }
    }
    if(!found) found = array[array.length - 1]
    if(found.length < 3) return
    let x = found[1]
    let y = found[2]
    image.style.objectPosition = `${x}% ${y}%`
  })
}

export const initObjectPositioning = () => {
  window.addEventListener('resize', objectPositioningHandler)
  objectPositioningHandler()
}