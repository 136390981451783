import gsap from '../../gsap/dist/gsap.js';
import axios from 'axios';
const rootEl = document.documentElement;
const body = document.body;
const loadingClass = 'search-in-progress';

const cardTemplates = document.getElementById('search-card-templates');
const simpleLinkCard = cardTemplates.querySelector('.card--simple-link');
const textLinkCard = cardTemplates.querySelector('.card--text-link');
const imageCard = cardTemplates.querySelector('.card--image');

const SEARCH_API = 'https://websitebuilder.fcbstudios.com/Search/Search';


function show() {
  rootEl.classList.add('search-is-active');
  const searchEl = document.getElementById('search');
  gsap.to(searchEl, {
    display: 'block',
    duration: 0.2,
    opacity: 1,
    onComplete: function () {
      document.getElementById('search-text-input').focus();
    }
  });
}

function hide() {
  const searchEl = document.getElementById('search');
  const nav = document.querySelector('nav');
  // rootEl.style.display = 'none'
  rootEl.classList.remove('search-is-active');
  searchEl.style.display = 'none'
  gsap.to(searchEl, {
    display: 'none',
    duration: 0.1,
    opacity: 0,
    onComplete: function () {
      rootEl.classList.remove('search-is-active');
      for (const item of nav.children) {
        item.classList.add('nav__link--hover')
      }
      setTimeout(() => {
        for (const item of nav.children) {
          item.classList.remove('nav__link--hover')
        }
      }, 1)
    }
  });
}

function showLoader() {
  body.classList.add(loadingClass);
}

function hideLoader() {
  body.classList.remove(loadingClass);
}

function showWarning() {
  const textInput = document.getElementById('search-text-input');
  textInput.parentElement.classList.add('search-is-empty');
  textInput.blur();
}

function hideWarning() {
  const textInput = document.getElementById('search-text-input');
  textInput.parentElement.classList.remove('search-is-empty');
}

function newSearch(warnIfEmpty = true) {
  const textInput = document.getElementById('search-text-input');
  const text = textInput.value;
  if (!text.length) {
    if (warnIfEmpty) {
      showWarning();
    }
    return;
  }
  showLoader();
  const activeFilter = document.querySelector('.js-search-radio:checked');
  const scopeId = activeFilter.dataset.scopeId;
  const data = {
    params: {
      scopeId: scopeId,
      term: text
    }
  }

  axios.get(SEARCH_API, data)
    .then(res => {
      const { data } = res;
      const container = document.getElementById('search-results');
      // Empty search results container first
      container.innerHTML = '';
      // Check if no results, add warning to user if so
      if (!data.length) {
        const warning = '<p>No results</p>';
        container.insertAdjacentHTML('beforeend', warning);
        return;
      }

      data.forEach(item => {
        // Create card container
        const card = document.createElement('div');
        card.classList.add('search__result', 'js-search-result');
        if(item.url?.includes('/people/')) card.classList.add('search__result--person')

        if(!simpleLinkCard && !textLinkCard && !imageCard) {
          console.log("Search card templates not found.")
          return
        }

        switch (item.cardType) {
          case 'simple-link':
            card.appendChild(simpleLinkCard.cloneNode(true));
            card.querySelector('a').href = item.url;
            card.querySelector('.card__title').innerHTML = item.text;
            card.querySelector('.card__label').innerHTML = item.label;
            break;
          case 'text-link':
            card.appendChild(textLinkCard.cloneNode(true));
            card.querySelector('a').href = item.url;
            card.querySelector('.rollover__label').innerHTML = item.label;
            card.querySelector('.card__overlay-title').innerHTML = item.title;
            card.querySelector('.card__label').innerHTML = item.name;
            break;
          case 'image':
            card.appendChild(imageCard.cloneNode(true));
            console.log(card)
            card.querySelector('a').href = item.url;
            card.querySelector('img').src = item.media.url;
            card.querySelector('img').alt = item.media.alt;
            card.querySelector('.rollover__label').innerHTML = item.label;
            card.querySelector('.card__overlay-title').innerHTML = item.title;
            card.querySelector('.card__label').innerHTML = item.name;
            break;
          default:
            break;
        }

        card.onclick = hide
        container.appendChild(card);
      });
      const newCards = container.querySelectorAll('.js-search-result')
      gsap.from(newCards, {
        duration: 0.2,
        opacity: 0,
        stagger: 0.1,
        y: 10
      })
    })
    .catch(error => {
      console.log(error);
    })
    .finally(() => {
      hideLoader();
    })
}

export const search = () => {
  // Attach event listeners to document so they still work when barba navigates between pages
  document.addEventListener('submit', function (e) {
    const form = e.target.closest('#search-form');
    if (!form) return;
    e.preventDefault();
    newSearch();
  });

  // Listen for search filter input changes
  document.addEventListener('change', function (e) {
    const radioInput = e.target.closest('.js-search-radio');
    if (!radioInput) return;
    newSearch(false);
  });

  // Hide empty text warning if user goes to enter new search term
  document.addEventListener('focus', function (e) {
    const textInput = e.target.closest('#search-text-input');
    if (textInput) {
      hideWarning();
    }
  }, true);

  // Toggle search modal
  document.addEventListener('click', function (e) {
    const searchButton = e.target.closest('#show-search');
    const closeButton = e.target.closest('#close-search');

    if (searchButton) {
      e.preventDefault();
      show();
    }

    if (closeButton) {
      hide();
    }
  });
}
