import Player from '@vimeo/player'
const playVimeo = (iframe) => {
  const player = new Player(iframe);
  player.play()
}

const pauseVimeo = (iframe) => {
  const player = new Player(iframe);
  player.pause()
}

export { playVimeo, pauseVimeo }
export default { playVimeo, pauseVimeo }