import barba from '@barba/core';
import gsap from '../../gsap/dist/gsap.js';
import { mountHeroSlider } from './slider';
import { initReveal } from './reveal';
import { sideNav } from './side-nav';
import { initVideos } from './video';
import { initShuffle } from './shuffle';
import { carousel } from './carousel';
import { setupLazyImages } from './lazy-image';

let loader;
let triggerCurrent;
let triggerNext;

const mobileLoader = document.querySelector('#js-mobile-loader');
const transitionDuration = 0.7;

const slideOverLeave = (data) => {
  triggerCurrent = document.querySelector(`.nav__link--${data.next.namespace}`);
  loader = triggerCurrent.querySelector('.nav__loader');

  document.documentElement.classList.add('transition-active');
  triggerCurrent.classList.add('is-moving');

  mobileLoader.classList.add(data.next.namespace);
  mobileLoader.classList.remove('hidden');

  return (
    gsap
      .timeline()
      .to(loader, {
        duration: 0,
        onComplete: () => {
          // Make loader visible
          loader.classList.remove('hidden');
          // Slide nav link and loader over to side
          setTimeout(() => {
            triggerCurrent.classList.add(`move-${data.next.namespace}`);
            mobileLoader.classList.add('active');
          }, 0);
        }
      })
      // Empty keyframe runs for 0.5 seconds to allow 'move-left/right' transition to run
      .to({}, { duration: transitionDuration })
  );
};

const slideOverAfter = (data) => {
  setTimeout(() => {
    loader.classList.add('collapse');
    mobileLoader.classList.remove('active');
  }, 0);

  // Empty keyframe runs for 0.5 seconds to allow 'collapse' transition to run
  return gsap.timeline().to(
    {},
    {
      duration: transitionDuration,
      onComplete: () => {
        // After 0.5 seconds, reset everything
        document.documentElement.classList.remove('transition-active');
        triggerCurrent.classList.remove(
          'is-moving',
          `move-${data.next.namespace}`
        );
        triggerCurrent.classList.add('active');
        loader.classList.remove('collapse');
        loader.classList.add('hidden');
        mobileLoader.classList.add('hidden');
        mobileLoader.classList.remove(data.next.namespace);
      }
    }
  );
};

const slideBackLeave = (data) => {
  triggerCurrent = document.querySelector(
    `.nav__link--${data.current.namespace}`
  );
  loader = triggerCurrent.querySelector('.nav__loader');

  document.documentElement.classList.add('transition-active');
  triggerCurrent.classList.add('is-moving');
  loader.classList.remove('hidden');
  loader.classList.add('collapse');

  mobileLoader.classList.add(data.current.namespace);
  mobileLoader.classList.remove('hidden');

  return (
    gsap
      .timeline()
      .to(loader, {
        // This small delay ensures the browser properly applies
        // the transition after removing the collapse class
        duration: 0.1,
        onComplete: () => {
          loader.classList.remove('collapse');
          mobileLoader.classList.add('active');
        }
      })
      // Wait 0.5 seconds for collapse transition to run
      .to({}, { duration: transitionDuration })
  );
};

const slideBackAfter = (data) => {
  return (
    gsap
      .timeline()
      .to(triggerCurrent, {
        duration: 0,
        onComplete: () => {
          // Apply transition for nav link to slide back to original position
          triggerCurrent.classList.add(`reset-${data.current.namespace}`);
          mobileLoader.classList.remove('active');
        }
      })
      // Empty keyframe runs for 0.5 seconds to allow slide transition to run
      .to(
        {},
        {
          duration: transitionDuration,
          onComplete: () => {
            // Reset everything
            document.documentElement.classList.remove('transition-active');
            triggerCurrent.classList.remove(
              'is-moving',
              `reset-${data.current.namespace}`,
              'active'
            );
            loader.classList.add('hidden');
            mobileLoader.classList.add('hidden');
            mobileLoader.classList.remove(data.current.namespace);
          }
        }
      )
  );
};

const slideAcrossLeave = (data) => {
  triggerCurrent = document.querySelector(
    `.nav__link--${data.current.namespace}`
  );
  triggerNext = document.querySelector(`.nav__link--${data.next.namespace}`);
  loader = triggerNext.querySelector('.nav__loader');

  document.documentElement.classList.add('transition-active');
  triggerNext.classList.add('is-moving');
  loader.classList.add('collapse');
  loader.classList.remove('hidden');

  mobileLoader.classList.add(data.next.namespace);
  mobileLoader.classList.remove('hidden');

  return (
    gsap
      .timeline()
      .to(loader, {
        // This small delay ensures the browser properly applies
        // the transition after removing the collapse class
        duration: 0.1,
        onComplete: () => {
          loader.classList.remove('collapse');
          mobileLoader.classList.add('active');
          // Apply transitions to reset position of current nav link
          // And move next nav link to new position
          triggerCurrent.classList.add(`reset-${data.current.namespace}`);
          triggerNext.classList.add(`move-${data.next.namespace}`);
        }
      })
      // Wait 0.5 seconds for collapse transition to run
      .to({}, { duration: transitionDuration })
  );
};

const slideAcrossAfter = (data) => {
  return (
    gsap
      .timeline()
      .to(triggerCurrent, {
        duration: 0,
        onComplete: () => {
          loader.classList.add('collapse');
          mobileLoader.classList.remove('active');
        }
      })
      // Empty keyframe runs for 0.5 seconds to allow slide transition to run
      .to(
        {},
        {
          duration: transitionDuration,
          onComplete: () => {
            // Reset everything
            document.documentElement.classList.remove('transition-active');
            triggerCurrent.classList.remove(
              `reset-${data.current.namespace}`,
              'active'
            );
            triggerNext.classList.remove(
              'is-moving',
              `move-${data.next.namespace}`
            );
            triggerNext.classList.add('active');
            loader.classList.add('hidden');
            loader.classList.remove('collapse');
            mobileLoader.classList.add('hidden');
            mobileLoader.classList.remove(data.next.namespace);
          }
        }
      )
  );
};

export const initNavigation = () => {
  barba.init({
    transitions: [
      {
        name: 'slide-over',
        from: {
          namespace: ['center']
        },
        to: {
          namespace: ['left', 'right']
        },
        leave: slideOverLeave,
        after: slideOverAfter
      },
      {
        name: 'slide-back',
        from: {
          namespace: ['left', 'right']
        },
        to: {
          namespace: ['center']
        },
        leave: slideBackLeave,
        after: slideBackAfter
      },
      {
        name: 'full-slide-right',
        from: {
          namespace: ['left']
        },
        to: {
          namespace: ['right']
        },
        leave: slideAcrossLeave,
        after: slideAcrossAfter
      },
      {
        name: 'full-slide-left',
        from: {
          namespace: ['right']
        },
        to: {
          namespace: ['left']
        },
        leave: slideAcrossLeave,
        after: slideAcrossAfter
      }
    ]
  });

  barba.hooks.beforeEnter(() => {
    // All page specific init calls happen here
    mountHeroSlider();
    initReveal();
    initVideos();
    initShuffle();
  });

  barba.hooks.after((navObject) => {
    carousel();
    sideNav();
    setupLazyImages();
    // Remove search active class if present
    const rootEl = document.documentElement;
    rootEl.classList.remove('search-is-active');

    const { trigger } = navObject;
    if (trigger.hasAttribute('data-keep-scroll')) {
      return;
    }

    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  });
};
